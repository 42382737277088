<template>
    <div>
        <b-card class="text-center" v-if="message.message" :bg-variant="message.isError ? 'danger' : 'success'" text-variant="white">
            <b-card-body>
                <h3 style="font-weight: bold;" v-html="message.message"></h3>
            </b-card-body>
        </b-card>

        <b-card class="text-center" v-else-if="transferItems.length === 0">
            <template #header>
                <h4><strong>Отсканируйте ШК</strong></h4>
            </template>
            <b-card-body>
                <h5>Отсканируйте ШК партии для передачи в фасовку</h5>
            </b-card-body>
        </b-card>

        <template v-else-if="transferItems.length > 0">
            <b-row>
                <b-col>
                    <b-button @click="reset" variant="danger" block style="font-weight: bold;">Очистить все</b-button>
                </b-col>
            </b-row>

            <b-card class="text-center" v-for="item in transferItems" :key="item.nomenclature_lot_id">
                <template #header>
                    <h4><strong>{{ item.nomenclature_name }}</strong></h4>
                </template>
                <b-row>
                    <b-col>
                        <h5>Доступно: {{ item.remains_count }} {{ item.nomenclature_measure_name }}</h5>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Количество"
                                      description="Введите количество для передачи"
                                      class="text-left"
                        >
                            <b-form-input v-model="item.count" :state="checkItemCount(item)" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row style="margin-bottom: 15px;">
                    <b-col>
                        <b-button @click="item.count = 280" variant="warning" block>280</b-button>
                    </b-col>
                    <b-col>
                        <b-button @click="item.count = 320" variant="warning" block>320</b-button>
                    </b-col>
                    <b-col>
                        <b-button @click="item.count = 340" variant="warning" block>340</b-button>
                    </b-col>
                </b-row>
            </b-card>

            <b-row>
                <b-col>
                    <b-button @click="makeTransfer" variant="primary" block :disabled="!isTransferAllowed">Передать</b-button>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
    import {
        PACK_TRANSFER_INFO_BY_BARCODE_PATH,
        PACK_TRANSFER_ACT_DATA_PATH,
        MATERIAL_TRANSFER_ACT_ENDPOINT
    } from '../../utils/endpoints';
    import {API_HOST, API_PATH} from '../../config/_entrypoint';
    import {filter, map, values, get} from 'lodash';
    import BarcodeRepository from '../../utils/BarcodeRepository';

    export default {
        name: "PackTransferInterface",
        data() {
            return {
                actData: null,
                transferItems: [],
                message: {
                    message: null,
                    isError: false,
                },
                messageClearTimeout: null,
            };
        },
        computed: {
            isTransferAllowed() {
                return filter(this.transferItems, item => !this.checkItemCount(item)).length === 0;
            },
        },
        methods: {
            checkItemCount(item) {
                return item.count !== null && item.count > 0;
            },
            getBaseUrl() {
                return API_HOST + API_PATH;
            },
            getBarcodeInfo(barcode) {
                this.$http.get(this.getBaseUrl() + PACK_TRANSFER_INFO_BY_BARCODE_PATH + '/' + barcode)
                    .then(response => {
                        let item = response.body;
                        item.count = null;

                        let existingItemIndex = this.transferItems
                            .findIndex(value => value.nomenclature_lot_id === item.nomenclature_lot_id);

                        if (existingItemIndex === -1) {
                            this.transferItems.push(item);
                        }
                    })
                    .catch(response => {
                        this.showMessage(this.makeErrorMessage(response), true);
                    });
            },
            makeTransfer() {
                this.$http.post(this.getBaseUrl() + MATERIAL_TRANSFER_ACT_ENDPOINT, this.makeActData())
                    .then(response => {
                        this.showMessage('Выбранные номенклатуры успешно пермещены', false);
                        this.reset();
                    })
                    .catch(response => {
                        this.showMessage(this.makeErrorMessage(response), true);
                    });
            },
            makeActData() {
                return {
                    ...this.actData,
                    force_accept: 1,
                    items: this.makeTransferItems(),
                };
            },
            makeTransferItems() {
                return map(this.transferItems, item => {
                    let boxName =
                        BarcodeRepository.boxPrefixes[1] +
                        Math.floor(Math.random() * (99999 - 10000 + 1) + 10000).toString();

                    return {
                        id: null,
                        count: parseFloat(item.count),
                        box: {name: boxName},
                        nomenclature_lot_id: item.nomenclature_lot_id,
                    };
                });
            },
            makeErrorMessage(response) {
                let errors = values(get(response.body, 'errors'));
                if (errors.length) {
                    return errors
                        .map(e => e.join('<br /><br />'))
                        .join('<br /><br />');
                }

                if (response.body.message) {
                    return response.body.message;
                }

                return 'Неизвестная ошибка';
            },
            showMessage(message, isError) {
                this.message.message = message;
                this.message.isError = isError;

                if (this.messageClearTimeout !== null) {
                    clearTimeout(this.messageClearTimeout);
                }

                this.messageClearTimeout = setTimeout(() => {
                    this.message.message = null;
                    this.message.isError = false;
                    this.messageClearTimeout = null;
                }, 3000);
            },
            getActData() {
                this.$http.get(this.getBaseUrl() + PACK_TRANSFER_ACT_DATA_PATH)
                    .then(response => {
                        this.actData = response.body;
                    })
                    .catch(response => {
                        this.showMessage(this.makeErrorMessage(response), true);
                    });
            },
            onBarcodeScanned(data) {
                this.getBarcodeInfo(data.barcode);
            },
            reset() {
                this.actData = null;
                this.transferItems = [];
                this.getActData();
            },
        },
        mounted() {
            this.$root.listenToBarcodeScanning(this);
            this.getActData();
        },
    }
</script>

<style scoped>

</style>
